body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.blog-footer {
  padding: 1px 0;
  color: #FFFF;
  text-align: center;
  background-color: #DDAC2E;
  border-top: .05rem solid #DDAC2E;


}

#texto {
  line-height: 15px;
  font-size: 17px;
  color: #FFFF;
}

#ano {
  font-size: 17px;
}